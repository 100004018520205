<template>
    <div class="list-view">
        <page-title>
            <el-button @click="addRow" type="primary" icon="el-icon-plus" :loading="downloading" class="filter-item">
                新增
            </el-button>
            <!-- <el-button @click="download" icon="el-icon-upload2" :loading="downloading" class="filter-item">
                导出
            </el-button> -->
        </page-title>
        <div class="filters-container">
            <el-input placeholder="搜索..." 
            v-model="search" 
            clearable
            class="filter-item search"
            @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            height="tableHeight"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="username" label="用户名" min-width="300"> </el-table-column>
            <el-table-column prop="nickname" label="昵称" min-width="300"> </el-table-column>
            <el-table-column label="头像" min-width="300">
                <template slot-scope="{ row }">
                    <el-image
                        style="width: 30px; height: 30px"
                        :src="row.avatar"
                        fit="cover"
                        :preview-src-list="[row.avatar]"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';
import ClipboardJS from 'clipboard';
const clickData = {};
export default {
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '',
            url: '/user/all',
            downloading: false
        };
    },
    computed: {
        ...mapState([]),
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return { search: this.search, query: { hasRole: 'ROLE_MINTER', minterProjectId: this.$store.state.projectId} };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/minterEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/minterEdit',
                query: {
                    id: row.id
                }
            });
        },
        download() {
            this.downloading = true;
            this.$axios
                .get('/user/excel', { responseType: 'blob' })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        clickId(row) {
            if (row.id !== clickData.id) {
                clickData.id = row.id;
                clickData.c = 0;
            }
            clickData.c = (clickData.c || 0) + 1;
            if (clickData.i) {
                clearInterval(clickData.i);
            }
            clickData.i = setTimeout(_ => {
                clickData.c = 0;
            }, 200);
            if (clickData.c === 5) {
                this.$http
                    .get(`/user/getToken/${row.id}`)
                    .then(res => {
                        let el = document.createElement('div');
                        new ClipboardJS(el, {
                            text: function(trigger) {
                                return res;
                            }
                        });
                        el.click();
                        this.$message.success('已复制Token');
                        clickData.c = 0;
                    })
                    .catch(e => {
                        this.$message.error(e.error);
                    });
            }
        }
    }
};
</script>
<style lang="less" scoped></style>
